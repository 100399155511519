
import "./index.scss";
import {useEffect, useState} from "react";
import {useGet} from "../../../api/request";

const Stats = () => {
 const getU = useGet();
  const [stats, setStats] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [lastMonth, setLastMonth] = useState(false);

    const getAllReportsInfo = () => {
        setIsLoading(true)
let endpoint = '/users/current/statistics'

if(lastMonth) {
    endpoint = '/users/current/statistics?month=last'
}
        getU(endpoint)
            .then((response) =>{
                if (response.status === 'success'){
                    setStats(response.data)
                   
                }
            })
            .catch((e) =>{
                alertU({
                    status: 'error',
                    title: 'Получение заявок',
                    message: e.response.data.message
                });
            })
            .finally(() =>{
                setIsLoading(false)

            })
      
    }


useEffect(() => {
    getAllReportsInfo()

}, [lastMonth])

    return <div className="main-container">
<div className="time-container">
    <button className={`time-btn ${!lastMonth && 'btn-active'}`}onClick={() => setLastMonth(false)}>За текущий месяц</button>
    <button className={`time-btn btn-margin-left ${lastMonth && 'btn-active'}`} onClick={() => setLastMonth(true)}>За предыдущий месяц</button>
</div>
<div className="stats-container">
<div className="main-container-stat border-gray">
<h3 className="header-stat">Отработано часов</h3>
<span className="text-stat">{stats.statistics?.hours}</span>
    </div>
    <div className="main-container-stat border-gray">
<h3 className="header-stat">Количество заявок</h3>
<span className="text-stat">{stats.statistics?.applicationsCount}</span>
    </div>
    <div className="main-container-stat border-gray">
<h3 className="header-stat">Количество лидов</h3>
<div>
<span className="text-stat">{stats.statistics?.contactApplicationsCount} </span>
<span className="text-stat-percent">
  {stats.statistics?.applicationsCount > 0 ? 
    ((stats.statistics.contactApplicationsCount / stats.statistics.applicationsCount) * 100).toFixed(2) 
    : 
    "0"}%
</span>
</div>
    </div>
    <div className="main-container-stat border-purple">
<h3 className="header-stat">Количество клиентов</h3>
<span className="text-stat">{stats.statistics?.paidApplicationsCount}</span>
    </div>

    <div className="main-container-stat border-purple">
<h3 className="header-stat">KPI</h3>
<span className="text-stat">{stats.statistics?.kpi}</span>
    </div>
    <div className="main-container-stat border-purple">
<h3 className="header-stat">Совершено сделок</h3>
<span className="text-stat">{stats?.payments}</span>
    </div>
    </div>
    </div>
}


export default Stats