import "./index.scss";
import Nav from "../../components/Nav";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import Loader from "../../components/Loader";
import {useGet} from "../../api/request";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import ShowFullInfoIcon from "../../assets/icons/ShowFullInfoIcon";
import ShowMoreButton from "../../components/ShowMoreButton";
import Modal from "../../components/Modal";
import EyeIcon from "../../assets/icons/EyeIcon";
import PerfectScrollbar from 'react-perfect-scrollbar';
import Card from "@material-ui/core/Card";
import {useAlert} from "../../components/AlertNotification";

const Applications = ({type}) => {

    const getU = useGet();
    const alertU = useAlert();

    const [isLoading, setIsLoading] = useState(true);

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(20);
    const [count, setCount] = useState(0);
    const [applications, setApplications] = useState([]);
    const [searchQuery, setSearchQuery] = useState('')
    const [inputValue, setInputValue] = useState('')
    const [modalStatus, setModalStatus] = useState({
        isActive: false,
        id: 0,
    });

    const CELL_STYLE = {
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "15px",
        letterSpacing: "1px",
        color: "#5E5873",
        fontFamily: 'Poppins, sans-serif',
        textAlign: 'center'

    }


    const INFO_CELL_STYLE = (status) => {
        let color = "#6E6B7B"

        if(status) {
            color = getColorForStatus(status)
        }
        return {
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "21px",
            color: color,
            textAlign: "left",
            fontFamily: 'Poppins, sans-serif'
        }
    } 

    const getColorForStatus = (status) => {
        const STATUS_COLOR = [
            {
                status: 'Не активна',
                color: '#008ECA'
            }, 
            {
                status: 'Отказ',
                color: '#A7000E'  
            },
            {
                status: 'Нецелевая',
                color: '#B4A8DE'  
            },
            {
                status: 'Получен контакт',
                color: '#FFC75A'  
            },
            {
                status: 'Успешно',
                color: '#7367F0'  
            },
            {
                status: 'Оплата',
                color: '#008B67'  
            }
    
        ]
    
     return STATUS_COLOR.find(st => st.status === status).color
        
    
    }
const handleSearch = (e) => {
    e.preventDefault()
if(inputValue) {
    setSearchQuery(inputValue)
} 
}
    const loadApplications = () => {
        setIsLoading(true);

        let statuses = []
        if(type === 'applications') {

           statuses = ['Не активна', "Нецелевая", 'Отказ']
        } 
        if(type === 'leads') {
            statuses = [ 'Получен контакт', 'Успешно']
         } if (type === 'clients') {

            statuses = ["Оплата"]
         }
      

        const statusParams = statuses.map(key => `status=${key}`).join('&');
  
        let endpoint = `/applications?page=${page + 1}&limit=${limit}&${statusParams}`;
     if(searchQuery) {
    endpoint = `/applications?page=${page + 1}&limit=${limit}&${statusParams}&searchQuery=${searchQuery}`;
}


        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setApplications(resp.data.applications);
                    setCount(resp.data.totalCount);
                }
            })
            .catch(() => {
                setApplications([]);
                setCount(0);
                alertU({
                    status: 'error',
                    title: 'Получение заявок',
                    message: 'Произошла ошибка при попытке получить заявки'
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };


    useEffect(() => {
        loadApplications();
    }, [page, limit, searchQuery]);


    return (
        <>
            <div className="applications">
                <div className="applications__header">
                    <Nav/>
                </div>
                <form>

                <input value={inputValue} onChange={(e) => setInputValue(e.target.value)} className="applications__search-bar" placeholder="Поиск заявок по почте, номеру, ссылке или номеру телефона"></input>
                <button onClick={handleSearch} className="applications__search-btn" >Поиск</button>
                </form>
             
                <div className="applications__list">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={CELL_STYLE}>
                                    № Заявки 
                                </TableCell>
                                <TableCell style={CELL_STYLE}>
                                    Статус
                                </TableCell>
                                <TableCell style={CELL_STYLE}>
                                    Сообщение
                                </TableCell>
                                <TableCell style={CELL_STYLE}>
                                    Платформа
                                </TableCell>
                                <TableCell style={CELL_STYLE}>
                                  Дата создания
                                </TableCell>
                                <TableCell style={CELL_STYLE}>

                                </TableCell>
                                <TableCell style={CELL_STYLE}>

                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {applications?.map((item) => (
                                <TableRow key={item?.id} hover={false}>
                                    <TableCell style={INFO_CELL_STYLE()}>
                                 №{item?.id || '---'}
                                    </TableCell>
                                    <TableCell style={INFO_CELL_STYLE(item?.status)}>
                                        {item?.status || '---'}
                                    </TableCell>
                                    <TableCell style={INFO_CELL_STYLE()}>
                                     <p>{item.message || '---'}</p> 
                                    </TableCell>
                                    <TableCell style={INFO_CELL_STYLE()}>
                                        {item.platform || '---'}
                                    </TableCell>
                                    <TableCell style={INFO_CELL_STYLE()}>
                                        {item.creationDate?.split('T')[0] || '---'}
                                    </TableCell>
                                    <TableCell>
                                        <Link to={`/app/main/edit-report/${item.id}`}>
                                            <div className="scaleIcon" style={{textAlign: 'right'}}>
                                                <EyeIcon />
                                            </div>
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        <div className="scaleIcon"
                                             onClick={() => setModalStatus({
                                                 id: item.id,
                                                 isActive: true
                                             })}
                                        >
                                            <ShowFullInfoIcon allowChangeStatus={true}/>
                                        </div>

                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    labelRowsPerPage={<span>Кол-во эл/стр:</span>}
                                    rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                    colSpan={7}
                                    count={count}
                                    rowsPerPage={limit}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeLimit}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </div>
                <div className="applications__mob-list">
                    <Card>
                        <PerfectScrollbar>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{width: 80}} style={{
                                                fontSize: "12px",
                                                fontWeight: 600,
                                                lineHeight: "15px",
                                                letterSpacing: "1px",
                                                color: "#5E5873",
                                                fontFamily: 'Poppins, sans-serif',
                                                minWidth: 90
                                            }}
                                                       align="center"
                                            >
                                                № Заявки
                                            </TableCell>
                                            <TableCell style={{
                                                fontSize: "12px",
                                                fontWeight: 600,
                                                lineHeight: "15px",
                                                letterSpacing: "1px",
                                                color: "#5E5873",
                                                fontFamily: 'Poppins, sans-serif',
                                                minWidth: 90
                                            }}
                                                       align="center"
                                            >
                                                Статус
                                            </TableCell>
                                            <TableCell style={{
                                                fontSize: "12px",
                                                fontWeight: 600,
                                                lineHeight: "15px",
                                                letterSpacing: "1px",
                                                color: "#5E5873",
                                                fontFamily: 'Poppins, sans-serif',
                                                minWidth: 90
                                            }}
                                                       align="center"
                                            >
                                                Сообщение 
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {applications?.map((item) => (

                                            <TableRow key={item?._id} hover={false}>
                                                <TableCell style={{
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    lineHeight: "21px",
                                                    color: "#6E6B7B",
                                                    fontFamily: 'Poppins, sans-serif',
                                                    minWidth: 90,
                                                    verticalAlign: 'top',
                                                    marginTop: 20
                                                }}
                                                           align="center"
                                                >
                                                 №{item?.id || '---'}
                                                </TableCell>
                                                
                                                <TableCell style={{
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    lineHeight: "21px",
                                                    color: "#6E6B7B",
                                                    fontFamily: 'Poppins, sans-serif',
                                                    minWidth: 90,
                                                    verticalAlign: 'top',
                                                    marginTop: 20
                                                }}
                                                           align="center"
                                                >
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center'
                                                    }}>
                                                        <div>
                                                            {item?.status || '---'}
                                                        </div>
                                                        <div style={{marginTop: 20}}>
                                                            <Link to={item.status !== 'Оплата' && `/app/main/edit-report/${item.id}`}>
                                                                <ShowMoreButton disabled={item.status === 'Оплата'}/>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </TableCell>

                                                <TableCell style={{
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    lineHeight: "21px",
                                                    color: "#6E6B7B",
                                                    fontFamily: 'Poppins, sans-serif',
                                                    minWidth: 90,
                                                    verticalAlign: 'top',
                                                    marginTop: 20
                                                }}
                                                           align="center"
                                                >
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center'
                                                    }}>
                                                        <div>
                                                            {item?.platform || "---"}
                                                        </div>

                                                   
                                                    </div>
                                                </TableCell>

                                                <TableCell style={{
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    lineHeight: "21px",
                                                    color: "#6E6B7B",
                                                    fontFamily: 'Poppins, sans-serif',
                                                    minWidth: 90,
                                                    verticalAlign: 'top',
                                                    marginTop: 20
                                                }}
                                                           align="center"
                                                >
                                               <div>{item?.platform || '---'}</div> 
                                                </TableCell>
                                                <TableCell style={{
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    lineHeight: "21px",
                                                    color: "#6E6B7B",
                                                    fontFamily: 'Poppins, sans-serif',
                                                    minWidth: 90,
                                                    verticalAlign: 'top',
                                                    marginTop: 20
                                                }}
                                                           align="center"
                                                >
                                              <div> {item?.creationDate?.split("T")[0] || '---'}</div> 
                                              <div style={{marginTop: 20, cursor: "pointer"}}
                                                             onClick={() => setModalStatus({
                                                                 id: item.id,
                                                                 isActive: true
                                                             })}
                                                        >
                                                            <ShowFullInfoIcon/>
                                                        </div>
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                labelRowsPerPage={<span>Эл/Стр:</span>}
                                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                colSpan={7}
                                                count={count}
                                                rowsPerPage={limit}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeLimit}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                        </PerfectScrollbar>
                    </Card>
                </div>

            </div>
            {modalStatus.id !== 0 &&
            <Modal modalStatus={modalStatus} setModalStatus={setModalStatus}/>
            }
            {isLoading && (
                <Loader/>
            )}
        </>
    );
};

export default Applications;
