import { useNavigate } from "react-router-dom";
import { useGet, usePut } from "../../api/request";
import { useAlert } from "../AlertNotification";
import { useEffect, useState } from "react";
import Selector from "../Selector";
import Loader from "../Loader";
import "./styles.scss";
import ModalReport from "../ModalReport/ModalReport";

const ModalCatalog = ({ modalStatus, setModalStatus, statusMap }) => {
  const navigate = useNavigate();
  const getU = useGet();
  const putU = usePut();
  const alertU = useAlert();
  const [errors, setErrors] = useState({
    status: "",
  });
  const [values, setValues] = useState({
    status: "",
  });

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const handleChangeStatus = (event) => {
    setValues({ status: event });
    setErrors({ status: "" });
  };

  const [isLoading, setIsLoading] = useState(false);

  const validation = () => {
    let isValid = true;

    if (values.status === "") {
      setErrors({ status: "Поле не должно быть пустым" });
      isValid = false;
    }

    return isValid;
  };

  const updateStatus = () => {
    if (!validation()) {
      return;
    }

if(values.status === 'Выполнена') {
  setModalIsOpen(true)
}

  
    const data = {
      status: statusMap[values.status],
    };

    const endpoint = `catalog/records/status/${modalStatus.id}`;

    putU(endpoint, data)
      .then((resp) => {
        if (resp.status === "success") {
          alertU({
            status: "success",
            title: "Обновление статуса",
            message: "Вы успешно обновили статус записи",
          });

       
        }
      })
      .catch((e) => {
        alertU({
          status: "error",
          title: "Обновление статуса",
          message: "Не удалось обновить статус записи",
        });
      })
      .finally(() => {
        setIsLoading(false);
      
      });
  };



  return (
    <div
      className={modalStatus.isActive ? "modal-status active" : "modal-status"}
      onClick={setModalStatus}
    >
      {modalIsOpen ? <ModalReport reportData={modalStatus.report} setModalIsOpen={setModalIsOpen}/> :    <div
        className={
          modalStatus.isActive
            ? "modal-status__content active"
            : "modal-status__content"
        }
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-status__content__title">{modalStatus.title}</div>

        <div className="main__form">
          <div className="main__form-one-inputs-block">
            <div className="main__form-input-alone">
              <Selector
                title="Статус"
                name="status"
                onChange={handleChangeStatus}
                value={values.status}
                error={errors.status}
                placeholder="Выберите статус"
                listOfCountry={[
                  { name: "Отклонена" },
                  { name: "В работе" },
                  { name: "Выполнена" },
                ]}
              />
            </div>
          </div>

          <div className="main__form__buttons">
            <button className="main__form__buttons-save" onClick={updateStatus}>
              Сохранить
            </button>
            <button
              className="main__form__buttons-discard"
              onClick={setModalStatus}
            >
              Выйти
            </button>
          </div>
        </div>
      </div> }
   
      {isLoading && <Loader />}
    </div> 
  );
};

export default ModalCatalog;
