import axios from 'axios';
import TokenStorage from "./TokenStorage";

let transport;


export const configureTransport = (token = null) => {


	const options =  token || TokenStorage.getAccessToken() ?
		{
			baseURL: process.env.REACT_APP_API_URL,
			headers: {
				Authorization: `Bearer ${token || TokenStorage.getAccessToken()}`,
			},
		}
		: { baseURL: process.env.REACT_APP_API_URL };
	transport = axios.create(options);
	return transport;
};

export const getTransport = () => transport || configureTransport();
