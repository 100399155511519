import {useEffect} from "react";
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {configureTransport} from '../../api/transport';
import {get, useGet} from '../../api/request';
import {setTokens, setUser} from '../../redux/auth';


export const useFetchUser = () => {
    // const accessToken = useSelector(state => state.auth.accessToken);
    // const dispatch = useDispatch();
    // const getU = useGet();
    //
    // return (callback = () => {
    // }) => {
    //     if (!accessToken) {
    //         dispatch(setUser());
    //         return;
    //     }
    //
	// 	dispatch(setUser({
	// 		avatar: 'https://i.pinimg.com/736x/f5/27/41/f52741fb62bf1d821948a49204406bdc.jpg',
	// 		login: 'User',
	// 		points: 1,
	// 		notification: 1,
	// 	}));
	// 	callback()

        // getU('users')
        //     .then((response) => {
        //         dispatch(setUser(response.data.user));
        //         callback();
        //     })
        //     .catch((e) => {
        //         console.log(e);
        //     })
    // };
}


const AuthController = ({children}) => {
    // const navigate = useNavigate();
    // const accessToken = useSelector(state => state.auth.accessToken);
    // const fetchUser = useFetchUser();
    // const dispatch = useDispatch();
    //
    // useEffect(() => {
    //
    //     get(``)
    //         .then((response) => {
    //             dispatch(setTokens({
    //                 accessToken: response.data.tokens.access_token,
    //                 refreshToken: response.data.tokens.refresh_token
    //             }));
    //         })
    //         .catch((e) => {
    //             console.log(e);
    //         })
    // }, [])
    //
    // useEffect(() => {
    //     configureTransport(accessToken);
    //     fetchUser(() => {
    //         navigate('/');
    //     })
    // }, [accessToken]);

    return children;
}

export default AuthController;
