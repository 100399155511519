import React, {useContext, useEffect, useState} from 'react';
import "./styles.scss"
import ReportInput from "../ReportInput";
import Selector from "../Selector";
import {useNavigate, useParams} from "react-router-dom";
import {useGet, usePut} from "../../api/request";
import GlobalError from "../GlobalError";
import Loader from "../Loader";
import {useAlert} from "../AlertNotification";
import { ActionContext } from '../../context/actionContext';

const Modal = ({modalStatus, setModalStatus}) => {
    const { action, setAction } = useContext(ActionContext);
    const navigate = useNavigate();
    const getU = useGet();
    const putU = usePut();
    const alertU = useAlert();

    const [isLoading, setIsLoading] = useState(true);

    const [values, setValues] = useState({
        status: '',
        comment: '',
        phone: '',
        email: '',
        anotherContact: ''
    })

    const [errors, setErrors] = useState({
        status: '',
        comment: '',
        phone: '',
        email: '',
        anotherContact: ''
    })

    const [listOfStatuses, setListOfStatuses] = useState([])

    const handleChangeValues = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: ''
        });
    };

    const handleChangeStatus = (title) => {
        setValues({
            ...values,
            status: title
        });
        setErrors({
            ...errors,
            status: ''
        });
    };


    const validation = () => {
        let isValid = true;
        let formErrors = {...errors};

        if (values.status === '') {
            formErrors.status = 'Поле должно быть заполненно';
            isValid = false;
        }

        setErrors(formErrors);
        return isValid
    }


    const onSave = () => {
        if (!validation()) return;

        const data = {
            status: values.status
        }

        if (values.comment){
            data.comment = values.comment
        }
        if (values.phone){
            data.phone = values.phone
        }
        if (values.email){
            data.email = values.email
        }
        if (values.anotherContact){
            data.anotherContact = values.anotherContact
        }

        putU(`applications/${modalStatus.id}`, data)
            .then((response) => {
                if (response.status === "success") {
                    setAction(true)
                    alertU({
                        status: 'success',
                        title: 'Обновление статуса',
                        message: 'Вы успешно обновили статус заявки'
                    });
                    setModalStatus({
                        isActive: false,
                        id: 0,
                    })
                }
            })
            .catch((e) => {
                alertU({
                    status: 'error',
                    title: 'Обновление статуса',
                    message: e.response?.data?.message
                });
            })
            .finally(() => {
                setIsLoading(false)
            })
    }


    const loadLists = async () => {

        await getU(`applications/${modalStatus.id}`)
            .then((response) => {
                if (response.status === 'success') {
                    const data = response.data.application;

                    setValues({
                        status: data.status,
                        comment: data.comment,
                        phone: data.phone,
                        email: data.email,
                        anotherContact: data.anotherContact
                    })
                }
            })
            .catch(() => {
                alertU({
                    status: 'error',
                    title: 'Получение заявок',
                    message: 'Произошла ошибка при попытке получить данные'
                });
            })
            .finally(() => {

            })

        await getU("applications/statuses")
            .then((response) => {
                if (response.status === "success") {
                    setListOfStatuses(response.data.statuses)
                }
            })
            .catch(() => {
                alertU({
                    status: 'error',
                    title: 'Получение статусов',
                    message: 'Произошла ошибка при попытке получить статусы'
                });
            })
            .finally(() => {
                setIsLoading(false)
            })

    }

    useEffect(() => {
        loadLists();
    }, [])

    return (
        <div className={modalStatus.isActive ? 'modal-status active' : "modal-status"}
             onClick={() => setModalStatus({
                 id: 0,
                 isActive: false
             })}
        >
            <div className={modalStatus.isActive ? 'modal-status__content active' : "modal-status__content"}
                 onClick={e => e.stopPropagation()}
            >
                <div className="modal-status__content__title">
                    Заявка №{modalStatus.id}
                </div>


                <div className="main__form">
                    <div className="main__form-one-inputs-block">
                        <div className="main__form-input-alone">
                            <Selector
                                title="Статус"
                                name="status"
                                onChange={handleChangeStatus}
                                value={values.status}
                                error={errors.status}
                                placeholder="Выберите статус"
                                listOfCountry={listOfStatuses}
                            />
                        </div>
                    </div>
                    {(values.status === "Отказ" || values.status === "Получен контакт") &&
                    <div className="main__form-one-inputs-block">
                        <div className="main__form-input-alone">
                            <ReportInput
                                name="comment"
                                title="Комментарий"
                                value={values.comment}
                                onChange={handleChangeValues}
                                placeholder="Введите комментарий"
                                error={errors.comment}
                            />
                        </div>
                    </div>
                    }
                    {values.status === "Получен контакт" &&
                    <>
                        <div className="main__form-one-inputs-block">
                            <div className="main__form-input-alone">
                                <ReportInput
                                    name="email"
                                    title="Почта"
                                    value={values.email}
                                    onChange={handleChangeValues}
                                    placeholder="Введите почту"
                                    error={errors.email}
                                />
                            </div>
                        </div>
                        <div className="main__form-one-inputs-block">
                            <div className="main__form-input-alone">
                                <ReportInput
                                    name="phone"
                                    title="Номер телефона"
                                    value={values.phone}
                                    onChange={handleChangeValues}
                                    placeholder="Введите номер телефона"
                                    error={errors.phone}
                                />
                            </div>
                        </div>
                        <div className="main__form-one-inputs-block">
                            <div className="main__form-input-alone">
                                <ReportInput
                                    name="anotherContact"
                                    title="Дургие комнтакты"
                                    value={values.anotherContact}
                                    onChange={handleChangeValues}
                                    placeholder="Введите другой контакт"
                                    error={errors.anotherContact}
                                />
                            </div>
                        </div>
                    </>
                    }


                    <div className="main__form__buttons">
                        <button className="main__form__buttons-save" onClick={onSave}>
                            Сохранить
                        </button>
                        <button className="main__form__buttons-discard"
                                onClick={() => setModalStatus({
                                    id: 0,
                                    isActive: false
                                })}
                        >
                            Выйти
                        </button>
                    </div>
                </div>


            </div>
            {isLoading && (
                <Loader/>
            )}
        </div>
    );
};

export default Modal;
