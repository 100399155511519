import { useEffect, useRef, useState } from "react";
import "./styles.scss";
import ArrowDownIcon from "../../assets/icons/ArrowDownIcon";

const Selector = ({
  title,
  listOfCountry,
  value,
  error,
  onChange,
  placeholder,
  disabled,
}) => {
  const [isVisiblePopup, setIsVisiblePopup] = useState(false);
  const sortRef = useRef(null);

  const onChangeItem = (key) => {
    onChange(key);
    setIsVisiblePopup(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sortRef.current && !sortRef.current.contains(event.target)) {
        setIsVisiblePopup(false);
      }
    };
    document.body.addEventListener("click", handleClickOutside);
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="selector">
      <div className="input__title">{title}</div>
      <button
        disabled={disabled}
        className={`selector-selected ${error && "selector-selected-error"} ${
          disabled && " selector-selected-disabled"
        }`}
        ref={sortRef}
        onClick={() => setIsVisiblePopup(!isVisiblePopup)}
      >
        {value ? (
          <div className="selector-selected-item">{value}</div>
        ) : (
          <div className="selector-selected-item-null">{placeholder}</div>
        )}
        {!disabled && (
          <div
            className={`selector-selected-icon ${
              isVisiblePopup ? "opened-popup" : ""
            }`}
          >
            <ArrowDownIcon />
          </div>
        )}
      </button>
      {error && (
        <div className="input__error">
          <p className="input__error-text">{error}</p>
        </div>
      )}
      {isVisiblePopup && (
        <div className="selector__popup-menu">
          <ul>
            {listOfCountry?.map((item) => (
              <li onClick={() => onChangeItem(item.name)}>{item.name}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Selector;
