import "./index.scss";
import {useDispatch, useSelector} from "react-redux";
import {logout, setUser} from "../../redux/auth";
import TokenStorage from "../../api/TokenStorage";
import {Link, useNavigate} from "react-router-dom";
import UserInfoItem from "../../components/UserInfoItem";
import Loader from "../../components/Loader";
import {useEffect, useState} from "react";
import {useDelete, useGet, usePost, usePut} from "../../api/request";
import Confirm from "../../components/Confirm";


const ProfileSection = ({userInfo, onLogout, onOpenProfile}) => {

    return (
        <div className="profile-section">
            <div className="profile-section__ava-block">
                <div className="profile-section__ava-block-avatar" onClick={onOpenProfile}>
                    <img src={userInfo.avatar} alt="defaultUser"/>
                </div>
                <div className="profile-section__ava-block-name" onClick={onOpenProfile}>
                    {userInfo.username}
                </div>
                <div className="profile-section__ava-block-auther" onClick={onOpenProfile}>
                    {userInfo.role}
                </div>
            </div>
            <div className="profile-section__detailsBlock">
                <div className="profile-section__detailsBlock-title">
                    Details
                </div>
                <div className="profile-section__detailsBlock-underLine"/>
                <div className="profile-section__detailsBlock-info">
                    {
                        Object.entries(userInfo)?.map(([key, values], index) => {
                            return (
                                <UserInfoItem
                                    name={key}
                                    value={values}/>
                            )
                        })
                    }
                </div>
            </div>
            <div className="profile-section__button">
                <button onClick={onLogout}>
                    Выйти
                </button>
            </div>
        </div>
    );
};

export default ProfileSection;
