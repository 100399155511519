import { Navigate } from "react-router-dom";
import Main from "../pages/Main/MainPage";
import Login from "../pages/Login";
import MainLayout from "../layouts/MainLayout";
import NotFound from "../pages/NotFound";
import LoginLayout from "../layouts/LoginLayout";
import Сustomers from "../pages/Сustomers";
import Leads from "../pages/Leads";
import Applications from "../pages/Applications";
import CreateReport from "../pages/Main/CreateReport";
import ShareInfo from "../pages/ShareInfo";
import Profile from "../pages/Profile";
import Catalogs from "../pages/Catalogs";
import Catalog from "./../pages/Catalog/index";

const navigation = [
  {
    path: "app",
    element: <MainLayout />,
    children: [
      { path: "main", element: <Main /> },
      { path: "main/create-report", element: <CreateReport /> },
      { path: "main/edit-report/:id", element: <ShareInfo /> },
      { path: "customers", element: <Сustomers /> },
      { path: "catalogs", element: <Catalogs /> },
      { path: "catalogs/:id", element: <Catalog /> },

      { path: "leads", element: <Leads /> },
      { path: "applications", element: <Applications /> },
      { path: "404", element: <NotFound /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/",
    element: <LoginLayout />,
    children: [
      { path: "login", element: <Login /> },

      { path: "app/profile", element: <Profile /> },

      { path: "/", element: <Navigate to="/app/main" /> },
      { path: "404", element: <NotFound /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default navigation;
