import Nav from "../../components/Nav";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useGet, usePost } from "../../api/request";
import { useAlert } from "../../components/AlertNotification";
import ShowFullInfoIcon from "../../assets/icons/ShowFullInfoIcon";
import styles from "./index.module.scss";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";

const Catalogs = () => {
  const [catalogs, setCatalogs] = useState();
  const getCatalogs = useGet();
  const postCatalog = usePost();
  const [isLoading, setIsLoading] = useState(true);
  const alertNotification = useAlert();

  const fetchData = () => {
    setIsLoading(true);

    let endpoint = `catalog`;

    getCatalogs(endpoint)
      .then((resp) => {
        if (resp.status === "success") {
          setCatalogs(resp.data.records);
        }
      })
      .catch(() => {
        setCatalogs([]);
        alertNotification({
          status: "error",
          title: "Получение каталогов",
          message: "Произошла ошибка при попытке получения каталогов",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.navigation}>
          <Nav />
        </div>
        <div>
          <Table className={styles.table}>
            <TableHead className={styles.thead}>
              <TableRow className={styles.thead__row}>
                <TableCell className={styles.thead__cell}>Название</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={styles.tbody}>
              {catalogs?.map((item, index) => (
                <TableRow key={item?.id} className={styles.tbody__row}>
                  <TableCell
                    className={styles.tbody__cell}
                    style={
                      index === catalogs.length - 1
                        ? {
                            borderBottomLeftRadius: 20,
                            borderBottomRightRadius: 20,
                            borderBottom: 0,
                          }
                        : null
                    }
                  >
                    <div>
                      {item?.name}
                      <Link to={`${item.id}`}>
                        <ShowFullInfoIcon allowChangeStatus={true} />
                      </Link>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
      {isLoading ? <Loader /> : null}
    </>
  );
};
export default Catalogs;
