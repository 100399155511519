import { useNavigate } from "react-router-dom";
import { useGet, usePut } from "../../api/request";
import { useAlert } from "../AlertNotification";
import { useEffect, useState } from "react";
import Selector from "../Selector";
import Loader from "../Loader";
import "./styles.scss";
import CreateReport from "../../pages/Main/CreateReport";

const ModalReport = ({setModalIsOpen, reportData}) => {
    const [reportWindowIsOpen, setReportWindowIsOpen] = useState(false)

  const [errors, setErrors] = useState({
    status: "",
  });
  const [values, setValues] = useState({
    status: "",
  });


  const handleChangeStatus = (event) => {
    setValues({ status: event });
    setErrors({ status: "" });
  };

  const [isLoading, setIsLoading] = useState(false);

  const validation = () => {
    let isValid = true;

    if (values.status === "") {
      setErrors({ status: "Поле не должно быть пустым" });
      isValid = false;
    }

    return isValid;
  };



  return (

      <div
        className={`modal-status__content active ${reportWindowIsOpen && 'report'}`
        }
        onClick={(e) => e.stopPropagation()}
      >
        {reportWindowIsOpen ? <CreateReport setReportWindowIsOpen={setReportWindowIsOpen} setModalIsOpen={setModalIsOpen} modal={true} reportData={reportData}/> :     <div className="main__form">
          <div className="main__form-one-inputs-block">
          <div className="modal-status__content__title">Хотите ли Вы заполнить отчёт?</div>
          </div>

          <div className="container-btn">
            <button className="main__form__buttons-save" onClick={() => setReportWindowIsOpen(true)}>
             Да
            </button>
            <button
              className="main__form__buttons-discard"
              onClick={() => {setModalIsOpen(false)}}
            >
              Выйти
            </button>
          </div>
        </div> }

    
      </div>
  );
};

export default ModalReport;
