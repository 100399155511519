
import "./index.scss";
import {Link} from "react-router-dom";
import ShowFullInfoIcon from "../../assets/icons/ShowFullInfoIcon";
import EyeIcon from "../../assets/icons/EyeIcon";

const ReportAloneItem = ({id,status, date, modalStatus, setModalActive, allowChangeStatus}) => {

    const getColorForStatus = (status) => {
        const STATUS_COLOR = [
            {
                status: 'Не активна',
                color: '#008ECA'
            }, 
            {
                status: 'Отказ',
                color: '#A7000E'  
            },
            {
                status: 'Нецелевая',
                color: '#B4A8DE'  
            },
            {
                status: 'Получен контакт',
                color: '#FFC75A'  
            },
            {
                status: 'Успешно',
                color: '#7367F0'  
            },
            {
                status: 'Оплата',
                color: '#008B67'  
            }
    
        ]
    
     return STATUS_COLOR.find(st => st.status === status).color
        
    
    }
    return (
        <div className="report-main-container">
        <div className="report-alone-item">
            <div className="report-alone-item__name">
              №{id}
            </div>
            <div className="report-alone-item__status"
            
            style={{color: status && getColorForStatus(status)}}>
             {status}
           </div>
           <div className="report-alone-item__date">
             {date?.split('T')[0]}
           </div>  
      
            <Link to={`/app/main/edit-report/${id}`}>
                <div className="report-alone-item__icon-edit">
                    <EyeIcon/>
                </div>
            </Link>

            <div className="report-alone-item__icon-share"
                 onClick={() => 
                    {allowChangeStatus &&
                        setModalActive({
                     id,
                     isActive: true
                 })}}
            >
              
                <ShowFullInfoIcon allowChangeStatus={allowChangeStatus}/>
            </div>
       

        </div>
          
           </div>
    );
};

export default ReportAloneItem;
