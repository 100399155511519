import { useEffect, useState } from "react";
import { json, useParams } from "react-router-dom";
import { useGet } from "../../api/request";
import Nav from "../../components/Nav";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import styles from "./index.module.scss";
import ShowFullInfoIcon from "../../assets/icons/ShowFullInfoIcon";
import { useAlert } from "../../components/AlertNotification";
import Loader from "../../components/Loader";
import ModalCatalog from "../../components/ModalCatalog/ModalCatalog";

// const mockData = [
//   {
//     id: 1,
//     name: "мебель5",
//     "2gis": "some url",
//     mail_index: "12313212",
//     phones: [76464312323, 78463127211],
//     email: "mail@mil.ru",
//     sites: ["http://site1.come", "http://site2.ru"],
//     twitter: "twitter.com",
//     vk: "vk.com",
//     WhatsApp: ["https://wa.me/79858267116", "https://wa.me/79858267112"],
//     Viber: [
//       "viber://chat/?number=79930119171",
//       "viber://chat/?number=79930119144",
//     ],
//     Telegram: ["https://t.me/mebelnsk_54", "https://t.me/mebelnsk_53"],
//     Youtube: "youtube.com",
//     Skype: "skype.com",
//     freshness: true,
//     status: "В работе",
//     date_create: "2024-02-02 12:00:00",
//     date_update: "2024-02-02 13:00:00",
//   },
//   {
//     id: 1,
//     name: "мебель5",
//     "2gis": "some url",
//     mail_index: "12313212",
//     phones: [76464312323, 78463127211],
//     email: "mail@mil.ru",
//     sites: ["http://site1.come", "http://site2.ru"],
//     twitter: "twitter.com",
//     vk: "vk.com",
//     WhatsApp: ["https://wa.me/79858267116", "https://wa.me/79858267112"],
//     Viber: [
//       "viber://chat/?number=79930119171",
//       "viber://chat/?number=79930119144",
//     ],
//     Telegram: ["https://t.me/mebelnsk_54", "https://t.me/mebelnsk_53"],
//     Youtube: "youtube.com",
//     Skype: "skype.com",
//     freshness: true,
//     status: "Новая",
//     date_create: "2024-02-02 12:00:00",
//     date_update: "2024-02-02 13:00:00",
//   },
//   {
//     id: 1,
//     name: "мебель5",
//     "2gis": "some url",
//     mail_index: "12313212",
//     phones: [76464312323, 78463127211],
//     email: "mail@mil.ru",
//     sites: ["http://site1.come", "http://site2.ru"],
//     twitter: "twitter.com",
//     vk: "",
//     WhatsApp: ["https://wa.me/79858267116", "https://wa.me/79858267112"],
//     Viber: [
//       "viber://chat/?number=79930119171",
//       "viber://chat/?number=79930119144",
//     ],
//     Telegram: ["https://t.me/mebelnsk_54", "https://t.me/mebelnsk_53"],
//     Youtube: "youtube.com",
//     Skype: "skype.com",
//     freshness: true,
//     status: "Устарела",
//     date_create: "2024-02-02 12:00:00",
//     date_update: "2024-02-02 13:00:00",
//   },
//   {
//     id: 1,
//     name: "мебель5",
//     "2gis": "some url",
//     mail_index: "12313212",
//     phones: [76464312323, 78463127211],
//     email: "mail@mil.ru",
//     sites: ["http://site1.come", "http://site2.ru"],
//     twitter: "twitter.com",
//     vk: "",
//     WhatsApp: ["https://wa.me/79858267116", "https://wa.me/79858267112"],
//     Viber: [
//       "viber://chat/?number=79930119171",
//       "viber://chat/?number=79930119144",
//     ],
//     Telegram: ["https://t.me/mebelnsk_54", "https://t.me/mebelnsk_53"],
//     Youtube: "youtube.com",
//     Skype: "skype.com",
//     freshness: true,
//     status: "Выполнена",
//     date_create: "2024-02-02 12:00:00",
//     date_update: "2024-02-02 13:00:00",
//   },
// ];

const statusMap = {
  Новая: 0,
  Отклонена: 1,
  "В работе": 2,
  Выполнена: 3,
  Устарела: 4,
};

const Catalog = () => {
  const { id } = useParams();
  const getRecords = useGet();
  const alertU = useAlert();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [count, setCount] = useState(0);

  const [modalStatus, setModalStatus] = useState({
    isActive: false,
    title: null,
    id: null,
  });

  const handleModalStatus = (isUpdate = false) => {
    setModalStatus({
      isActive: false,
      title: null,
      id: null,
    });

    if (isUpdate) {
      fetchData();
    }
  };

  const fetchData = () => {
    setIsLoading(true);

    let endpoint = `catalog/records/all?page=${
      page + 1
    }&limit=${limit}&catalog_id=${id}`;

    getRecords(endpoint)
      .then((resp) => {
        if (resp.status === "success") {
          setData(resp.data.records);
          setCount(resp.data.totalCount);
        }
      })
      .catch(() => {
        setData([]);
        setCount(0);
        alertU({
          status: "error",
          title: "Получение заявок",
          message: "Произошла ошибка при попытке получения записей каталога",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    fetchData();
  }, [page, limit]);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.navigation}>
          <Nav />
        </div>
        <div>
          <Table className={styles.table}>
            <TableHead className={styles.thead}>
              <TableRow className={styles.thead__row}>
                <TableCell className={styles.thead__cell}>Название</TableCell>
                <TableCell className={styles.thead__cell}>Сайт</TableCell>
                <TableCell className={styles.thead__cell}>Контакты</TableCell>
                <TableCell className={styles.thead__cell}>
                  Социальные сети
                </TableCell>
                <TableCell className={styles.thead__cell}>Источник</TableCell>
                <TableCell className={styles.thead__cell}>Действие</TableCell>
                <TableCell className={styles.thead__cell}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={styles.tbody}>
              {data?.map((elem, index) => {
                return (
                  <TableRow
                    key={index}
                    className={`${styles.tbody__row} ${
                      elem.status === 0
                        ? styles.active
                        : elem.status === 4
                        ? styles.old
                        : null
                    } ${data.length - 1 === index ? styles.lastItem : null}`}
                  >
                    <td className={styles.tbody__cell}>
                      <p className={styles.tbody__cell_heading}>
                        {elem.name || "Нет"}
                      </p>
                    </td>
                    <td className={styles.tbody__cell}>
                      {JSON.parse(elem.sites)?.map((elem, index) => (
                        <a
                          key={index}
                          className={styles.tbody__cell_text}
                          href={elem}
                        >
                          {elem}
                        </a>
                      ))}
                      {JSON.parse(elem.sites).length === 0 ? (
                        <p className={styles.tbody__cell_text}>Нет</p>
                      ) : null}
                    </td>

                    <td className={styles.tbody__cell}>
                      {JSON.parse(elem.phones).map((elem) => (
                        <p className={styles.tbody__cell_text}>{elem}</p>
                      ))}
                      {JSON.parse(elem.Viber).map((elem, index) => (
                        <a
                          href={elem}
                          className={styles.tbody__cell_text}
                          key={index}
                        >
                          {elem}
                        </a>
                      ))}
                      {JSON.parse(elem.WhatsApp).map((elem, index) => (
                        <a
                          href={elem}
                          className={styles.tbody__cell_text}
                          key={index}
                        >
                          {elem}
                        </a>
                      ))}
                      {JSON.parse(elem.Telegram).map((elem, index) => (
                        <a
                          href={elem}
                          className={styles.tbody__cell_text}
                          key={index}
                        >
                          {elem}
                        </a>
                      ))}
                      {JSON.parse(elem.Telegram).length === 0 &&
                      JSON.parse(elem.WhatsApp).length == 0 &&
                      JSON.parse(elem.Viber).length === 0 &&
                      JSON.parse(elem.phones).length === 0 ? (
                        <p className={styles.tbody__cell_text}>Нет</p>
                      ) : null}
                    </td>
                    <td className={styles.tbody__cell}>
                      {elem.vk ? (
                        <a className={styles.tbody__cell_text} href={elem.vk}>
                          {elem.vk}
                        </a>
                      ) : (
                        <p className={styles.tbody__cell_text}>Нет</p>
                      )}
                    </td>
                    <td className={styles.tbody__cell}>
                      <p className={styles.tbody__cell_text}>
                        {<a href={elem["2gis"]}>{elem["2gis"]}</a> || "Нет"}
                      </p>
                    </td>
                    <td className={styles.tbody__cell}>
                      <p
                        className={`${styles.status} ${
                          elem.status === 3
                            ? styles
                            : elem.status === 4
                            ? styles.status_gray
                            : elem.status === 2
                            ? styles.status_blue
                            : elem.status === 1
                            ? styles.status_red
                            : null
                        }`}
                      >
                        {Object.keys(statusMap).find(
                          (key) => statusMap[key] === elem.status
                        )}
                      </p>
                    </td>
                    <td className={styles.tbody__cell}>
                      <ShowFullInfoIcon
                        onClick={() => {
                          setModalStatus({
                            isActive: true,
                            title: elem.name,
                            id: elem.id,
                            report: elem
                          });
                        }}
                      />
                    </td>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  labelRowsPerPage={<span>Эл/Стр:</span>}
                  rowsPerPageOptions={[10, 20, 30, 40, 50]}
                  colSpan={7}
                  count={count}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeLimit}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </div>
      {modalStatus.isActive ? (
        <ModalCatalog
          modalStatus={modalStatus}
          setModalStatus={handleModalStatus}
          statusMap={statusMap}
        />
      ) : null}
      {isLoading ? <Loader /> : null}
    </>
  );
};

export default Catalog;
