import {useState} from 'react';
import {useDispatch} from 'react-redux';
import './styles.scss';

const ReportInput = ({
                         value,
                         onChange,
                         error,
                         title,
                         isDisabled,
                         ...props
                     }) => {
    const [isFocus, setIsFocus] = useState(false);
    const dispatch = useDispatch();
    return (
        <div className={`input`}>
            <div className="input__title">
				{title}
            </div>
            <div className={`input__wrap${isFocus ? ' input__wrap_focus' : ''}${error ? ' input__wrap_error' : ''} ${props.disabled && 'input__wrap_disabled'}`}>
                <input
                    className="input__input"
                    type="text"
                    value={value}
                    onChange={onChange}
                    onFocus={() => {
                        setIsFocus(true);
                    }}
                    onBlur={() => {
                        setIsFocus(false);
                    }}
                 
                    {...props} />
            </div>
            {error && (
                <div className="input__error">
                    <p className="input__error-text">{error}</p>
                </div>
            )}
        </div>
    );
};

export default ReportInput;
