import { createTheme, createMuiTheme } from "@material-ui/core";

const theme = createTheme({
  tr: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
  table: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});

export default theme;
