import "./index.scss";
import Nav from "../../../components/Nav";
import Button from "../../../components/Button";
import {useNavigate} from "react-router-dom";
import ReportAloneItem from "../../../components/ReportAloneItem";
import Loader from "../../../components/Loader";
import {useEffect, useState} from "react";
import {useGet} from "../../../api/request";
import {useSelector} from "react-redux";
import Modal from "../../../components/Modal";
import {useAlert} from "../../../components/AlertNotification";
import Stats from "../Stats";


const Main = () => {
    const navigate = useNavigate();
    const getU = useGet();
    const alertU = useAlert();

    const isReadyToWork = useSelector(state => state.work.isReadyToWork)
    const [isLoading, setIsLoading] = useState(false);
    const [modalStatus, setModalStatus] = useState({
        isActive: false,
        id: 0,
    });

    const [reports, setReports] = useState([]);
  

    const getAllReportsInfo = () => {
        setIsLoading(true)

        getU('stats')
            .then((response) =>{
                if (response.status === 'success'){
                    setReports(response.data.applications)
                }
            })
            .catch((e) =>{
                alertU({
                    status: 'error',
                    title: 'Получение заявок',
                    message: e.response.data.message
                });
            })
            .finally(() =>{
                setIsLoading(false)

            })
      
    }
    const onMoveCreateReport = () => {
        navigate("/app/main/create-report")
    }



    useEffect(()=>{
        getAllReportsInfo()
    },[])


    return (
        <>
            <div className="main">
                <Nav/>
                <Stats setIsLoading={setIsLoading} />
                <div className="main__button">
                    <Button text={"Создать отчет"} onClick={onMoveCreateReport} isDisabled={isReadyToWork}/>
                </div>
                {
                    reports.length !== 0 &&
                    <div className="main__last-reports-block">
                        <div className="main__last-reports-block-title">
                            Последние заявки
                        </div>
                        <div className="main__last-reports-block-list">
                            {
                                reports?.map((item) =>
                                    <ReportAloneItem
                                        id={item.id}
                                        status={item.status}
                                        date={item.creationDate}
                                        setModalActive={setModalStatus}
                                        modalStatus={modalStatus}
                                        allowChangeStatus={item.status !== 'Оплата'}
                                    />
                                )
                            }
                        </div>
                    </div>

                }
            </div>
            {modalStatus.id !== 0 &&
            <Modal modalStatus={modalStatus} setModalStatus={setModalStatus}/>
            }
            {isLoading && (
                <Loader/>
            )}
        </>
    );
};

export default Main;
