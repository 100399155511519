import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {logout} from '../../redux/auth';
import {useNavigate} from 'react-router-dom';
import "./index.scss";
import {Outlet} from 'react-router-dom';
import {HashLink as Link} from 'react-router-hash-link';
import Container from "../../components/Container";

const LoginLayout = () => {

    return (
        <Container>
            <Outlet/>
        </Container>
    );
};

export default LoginLayout;
