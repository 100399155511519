import "./index.scss";
import { useContext, useEffect, useRef, useState } from "react";
import Stopwatch from "../../components/Stopwatch";
import { post, useGet, usePost } from "../../api/request";
import GlobalError from "../../components/GlobalError";
import TokenStorage from "../../api/TokenStorage";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/auth";
import { setIsReadyToWork } from "../../redux/work";
import StopwatchStorage from "../../api/StopwatchStorage";
import { useAlert } from "../../components/AlertNotification";
import StopwatchTextarea from "../../components/StopwatchTextarea";
import { ActionContext } from "../../context/actionContext";
import { Button } from "@material-ui/core";

const TimerSection = () => {
  const { action, setAction } = useContext(ActionContext);
  const postU = usePost();
  const getU = useGet()
  const alertU = useAlert();

  const dispatch = useDispatch();
  const user = TokenStorage.getUser();
  
  const [count, setCount] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [isDescription, setIsDescription] = useState(false);
  const [description, setDescription] = useState(null);

  const [running, setRunning] = useState(false);
  const [time, setTime] = useState(0);

  const [alert, setAlert] = useState({
    txt: "",
    isVisible: false,
  });

  const showAlert = (text) => {
    setAlert({
      txt: text,
      isVisible: true,
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        isVisible: false,
      });
    }, 2500);
  };

  const [test, setTest] = useState({
    prevTime: null,
    stopwatchInterval: null,
    elapsedTime: 0,
  });

  
  const [houses, setHouses] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  var updateTime = function () {
    var tempTime = test.elapsedTime;
    var milliseconds = tempTime % 1000;
    tempTime = Math.floor(tempTime / 1000);
    var seconds = tempTime % 60;
    tempTime = Math.floor(tempTime / 60);
    var minutes = tempTime % 60;
    tempTime = Math.floor(tempTime / 60);
    var hours = tempTime % 60;

    setHouses(hours);
    setMinutes(minutes);
    setSeconds(seconds);
  };

  const start = () => {
    const isStopwatchRunning = StopwatchStorage.getIsStopwatchRunning();

    if (isStopwatchRunning) {
      alertU({
        status: "error",
        title: "Таймер уже запущен",
        message: "Остановите предыдущий таймер, перед запуском следующего.",
      });

      return;
    }

    setRunning(true);
    StopwatchStorage.setIsStopwatchRunning(true);

    setTest({
      ...test,
      stopwatchInterval: setInterval(function () {
        if (!test.prevTime) {
          test.prevTime = Date.now();
        }

        test.elapsedTime += Date.now() - test.prevTime;
        test.prevTime = Date.now();

        updateTime();
      }, 50),
    });
  };

  const end = async () => {
    await pause();
    setTest({
      ...test,
      elapsedTime: null,
    });
    setHouses(0);
    setMinutes(0);
    setSeconds(0);
    await updateTime();
  };

  const pause = () => {
    if (test.stopwatchInterval) {
      clearInterval(test.stopwatchInterval);
      setTest({
        ...test,
        stopwatchInterval: null,
      });
    }
    setTest({
      ...test,
      prevTime: null,
    });
  };

  useEffect(() => {
    fetchUser()
  }, [])
  
    const fetchUser = () => {
      getU("users/current")
        .then((response) => {
          if (response.status === "success") {
      setCount(response.data.user.thisMonthPay)
      }
    }).catch(() => {
          showAlert("Произошла ошибка при получении данных о юзере");
        })
    }

  const handleSubmitTime = () => {
    setRunning(false);
    StopwatchStorage.setIsStopwatchRunning(false);

    let milliseconds = seconds * 1000 + minutes * 60000 + houses * 3600000;
    
    if(description == "")
        setDescription(null);

   

    postU("timetracker", { milliseconds, description })
      .then((response) => {
        if (response.status === "success") {
          TokenStorage.setUser({
            ...user,
            thisMonthPay: response.data.timeTrack.thisMonthPay,
          });
          dispatch(
            setUser({
              ...user,
              thisMonthPay: response.data.timeTrack.thisMonthPay,
            })
          );
          setCount(response.data.timeTrack.thisMonthPay);
          alertU({
            status: 'success',
            title: 'Сохранение времени',
            message: 'Ваши данные были успешно сохранены'
        });
          setTime(0);
          setAction(false)
          end();
        }
      })
      .catch(() => {
        showAlert("Произошла ошибка при попытке остановить время");
      })
      .finally(() => {
        if (test.stopwatchInterval) {
          clearInterval(test.stopwatchInterval);
          setTest({
            ...test,
            stopwatchInterval: null,
          });
        }
        setTest({
          ...test,
          prevTime: null,
        });
      });

    setDescription(null);
    setIsDescription(false);
  }
  const finish = () => {
    if(!action && !description) {
  
    setModalIsOpen(true)
    return null
    } else {
      handleSubmitTime()

    }

  };


  const onBeforeUnload = (e) => {
    StopwatchStorage.setIsStopwatchRunning(false);
    if (!running) {
      return;
    }
    var dialogText =
      "Закрыть сайт? Возможно, внесенные изменения не сохранятся.";
    e.returnValue = dialogText;
    return dialogText;
  };

  const handleChangeDescription = (evt) => {
    setDescription(evt.target.value);
  };

  useEffect(() => {
    window.onbeforeunload = onBeforeUnload;
    if (running) {
      dispatch(setIsReadyToWork(true));
    } else {
      dispatch(setIsReadyToWork(false));
    }
  }, [running]);

  return (
 
    <div className="timer-section">
      <div className="timer-section__timer-block">
        <div
          className="timer-section__timer-block-icon"
          style={{ display: running ? "none" : "flex" }}
          onClick={() => {
            start();
          }}
        >
          <img src="/img/startTimer.png" alt="img" />
        </div>
        <div
          className="timer-section__timer-block-icon"
          style={{ display: running ? "flex" : "none" }}
          onClick={() => {
            finish();
          }}
        >
          <img src="/img/stopTimer.png" alt="img" />
        </div>
        <div className="timer-section__timer-block-timer">
          <Stopwatch
            running={running}
            houses={houses}
            minutes={minutes}
            seconds={seconds}
          />
        </div>
      </div>
      {running && (
        <div className="timer-section__description-block">
          {!isDescription ? (
            <div className="timer-section__button">
              <button
                className="main__form__buttons-save"
                onClick={() => {
                  setIsDescription(true);
                }}
              >
                Добавить описание
              </button>
            </div>
          ) : (
            <StopwatchTextarea onChange={handleChangeDescription} />
          )}
        </div>
  
      )}
      <div className="timer-section__payment-block">
        <div className="timer-section__payment-block-title">К оплате:</div>
        <div className="timer-section__payment-block-count">{count} </div>
        <div className="timer-section__payment-block-count">&#8381;</div>
      </div>
      {alert.isVisible && (
        <GlobalError isVisible={alert.isVisible}>{alert.txt}</GlobalError>
      )}

{modalIsOpen &&  <div className="confirm-modal">
        <h3 className="modal-heading">Не видно, что было сделано за это время. Хотите отменить таймер?</h3>

 <div className="btn-container-modal">
 <Button color="primary" onClick={() => {
  end()
  setModalIsOpen(false)
 }}>Да</Button>
 <Button onClick={() => {setModalIsOpen(false)

 }}>Нет</Button>
</div> 

       

      </div>
}
    </div>

  );
};

export default TimerSection;
