import "./index.scss";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../redux/auth";
import TokenStorage from "../../api/TokenStorage";
import {Link, useNavigate} from "react-router-dom";
import UserInfoItem from "../../components/UserInfoItem";
import {useGet} from "../../api/request";
import React, {useEffect, useState} from "react";
import Loader from "../../components/Loader";
import GlobalError from "../../components/GlobalError";
import Confirm from "../../components/Confirm";

const MobProfileSection = ({userInfo, onLogout, onOpenProfile}) => {



    return (
        <div className="profile-mob-section">
            <div className="profile-mob-section__main-block">
                <div className="profile-mob-section__ava-block">
                    <div className="profile-mob-section__ava-block-avatar" onClick={onOpenProfile}>
                        <img src={userInfo.avatar} alt="defaultUser"/>
                    </div>
                    <div className="profile-mob-section__ava-block-name" onClick={onOpenProfile}>
                        {userInfo.username}
                    </div>
                    <div className="profile-mob-section__ava-block-auther" onClick={onOpenProfile}>
                        {userInfo.role}
                    </div>
                </div>
                <div className="profile-mob-section__button">
                    <button onClick={onLogout}>
                        Выйти
                    </button>
                </div>
            </div>

            <div className="profile-mob-section__detailsBlock">
                <div className="profile-mob-section__detailsBlock-title">
                    Details
                </div>
                <div className="profile-mob-section__detailsBlock-underLine"/>
                <div className="profile-mob-section__detailsBlock-info">
                    {
                        Object.entries(userInfo)?.map(([key, values], index) => {
                            return (
                                <UserInfoItem
                                    name={key}
                                    value={values}/>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default MobProfileSection;
