import "./index.scss";
import Nav from "../../../components/Nav";
import ReportInput from "../../../components/ReportInput";
import {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Selector from "../../../components/Selector";
import Textarea from "../../../components/Textarea";
import {useGet, usePost} from "../../../api/request";
import Loader from "../../../components/Loader";
import {useAlert} from "../../../components/AlertNotification";
import { ActionContext } from "../../../context/actionContext";

const CreateReport = ({modal, setReportWindowIsOpen, setModalIsOpen, reportData}) => {
    const { action, setAction } = useContext(ActionContext);
    const navigate = useNavigate();
    const getU = useGet();
    const postU = usePost();
    const alertU = useAlert();



    const [isLoading, setIsLoading] = useState(true);


    const [values, setValues] = useState({
        message: '',
        link: '',
        platform: '',
        theme: '',
        cost: '',
        files: []
        
    })

    const [errors, setErrors] = useState({
        message: '',
        link: '',
        platform: '',
        theme: '',
        cost: '',
        files: ''
       
    })

    const [listOfPlatforms, setListOfPlatforms] = useState([])
    const [listOfTheme, setListOfTheme] = useState([])
    const [files, setFiles] = useState([])
    const [filesError, setFilesError] = useState(false)
  
    const handleChangeValues = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: ''
        });

      
    };

    const handleChangeFilesValues = (event) => {
   
        const filesArray = Array.from(event.target.files)
      

      setFiles([...filesArray])
    

      
     
    };

    const handleChangeTheme = (name) => {
        setValues({
            ...values,
            theme: name
        });
        setErrors({
            ...errors,
            theme: ''
        });
    };

    const handleChangePlatform = (name) => {
        setValues({
            ...values,
            platform: name
        });
        setErrors({
            ...errors,
            platform: ''
        });
    };


    useEffect(() => {
        validateFiles();
    }, [files]);


    const validateFiles = () => {
        if(files.length > 3) {
            setFilesError(true)
    } else {
        setFilesError(false)
    }



    }

    const handleRemoveFile = (name) => {
 
        const filteredFiles = files.filter(file => file.name !== name); 
        setFiles(filteredFiles); 
    };


    const validation = () => {

        let isValid = true;
        let formErrors = {...errors};
        if (values.message === '') {
            formErrors.message = 'Поле должно быть заполненно';
            isValid = false;
        }

        if (values.link === '') {
            formErrors.link = 'Поле должно быть заполненно';
            isValid = false;
        }

        if (values.platform === '') {
            formErrors.platform = 'Поле должно быть заполненно';
            isValid = false;
        }

        if (values.theme === '') {
            formErrors.theme = 'Поле должно быть заполненно';
            isValid = false;
        }

        if (values.cost === '') {
            formErrors.cost = 'Поле должно быть заполненно';
            isValid = false;
        }

   
        setErrors(formErrors);
        return isValid
    }


    const onSave = () => {
     
        if (!validation()) return;

        setIsLoading(true)

        const formData = new FormData()

        formData.append('message', values.message);
formData.append('link', values.link);
formData.append('platform', values.platform);
formData.append('theme', values.theme);
formData.append('cost', values.cost);


if(reportData && modal) {
    let anotherContact = reportData.Telegram
    
   
if(!reportData.Telegram || reportData.Telegram === '[]' ) {

    anotherContact = reportData.WhatsApp
 
}
if(!reportData.WhatsApp || reportData.WhatsApp === '[]') {
    anotherContact = reportData.Skype

}
if((!reportData.Skype || reportData.Skype === '[]') && (reportData.Viber && reportData.Viber !== '[]')) {
    anotherContact = reportData.Viber
    console.log('sk')
} 
console.log(anotherContact)
    formData.append('phone', reportData.phones && reportData.phones);
formData.append('email', reportData.email && reportData.email);
formData.append('anotherContact', anotherContact && anotherContact);
}

files.forEach((file) => {
    formData.append('files', file);
});

        postU('applications', formData)
            .then((response) =>{
                if (response.status === "success"){
                    alertU({
                        status: 'success',
                        title: 'Создание анкеты',
                        message: 'Вы успешно создали новую заявку'
                    });
                    if(!modal) {
                        navigate('/app/main')
                    } else {
                        setModalIsOpen(false)
                    }
                 setAction(true)
                    setValues({
                        message: '',
                        link: '',
                        platform: '',
                        theme: '',
                        cost: '',
                       
                    })

               
                }
            })
            .catch((response) =>{
              
                if(response.response.data?.message === 'Запись с данной ссылкой уже существует') {
                    alertU({
                        status: 'error',
                        title: 'Получение заявок',
                        message: 'Запись с данной ссылкой уже существует'
                    })
                }
                  else {
                        alertU({
                            status: 'error',
                            title: 'Получение заявок',
                            message: 'Произошла ошибка при создании анкеты'
                        });

                    }
           
            })
            .finally(() =>{
                setIsLoading(false)
            })
    }

    const loadLists = async () => {
        await getU("platforms?page=1&limit=100")
            .then((response) =>{
                if (response.status === "success"){
                    setListOfPlatforms(response.data.platforms)
                }
            })
            .catch(() =>{
                alertU({
                    status: 'error',
                    title: 'Получение пллатформ',
                    message: 'Произошла ошибка при попытке получить платформы'
                });
            })
            .finally(() =>{

            })
        await getU("themes?page=1&limit=100")
            .then((response) =>{
                if (response.status === "success"){
                    setListOfTheme(response.data.themes)
                }
            })
            .catch(() =>{
                alertU({
                    status: 'error',
                    title: 'Получение тем',
                    message: 'Произошла ошибка при попытке получить темы'
                });
            })
            .finally(() =>{
                setIsLoading(false)
            })
    }





    useEffect(() => {
        loadLists();
    }, [])

    return (
        <>
            <div className="main">
               {!modal && <Nav/>} 
                <div className="main__form">
                <div className="main__title">
                Создание заявки
            </div>
                <div className="main__form-two-inputs-block">
                        <div className="main__form-input">
                            <ReportInput
                                name="link"
                                title="Ссылка на заявку"
                                value={values.link}
                                onChange={handleChangeValues}
                                placeholder="Введите ссылку на заявку"
                                error={errors.link}
                            />
                        </div>
                    </div>
                    <div className="main__form-one-inputs-block">
                        <div className="main__form-input-alone">
                            <Textarea
                                name="message"
                                title="Сообщение"
                                value={values.message}
                                onChange={handleChangeValues}
                                placeholder="Введите сообщение"
                                error={errors.message}
                            />
                        </div>
                    </div>
                    <div className="main__form-two-inputs-block">
                        <div className="main__form-input">
                            <Selector
                                title="Тема"
                                name="theme"
                                onChange={handleChangeTheme}
                                value={values.theme}
                                error={errors.theme}
                                placeholder="Выберите тему"
                                listOfCountry={listOfTheme}
                            />

                        </div>
                        <div className="main__form-input">
                            <Selector
                                title="Платформа"
                                name="country"
                                onChange={handleChangePlatform}
                                value={values.platform}
                                error={errors.platform}
                                placeholder="Выберите платформу"
                                listOfCountry={listOfPlatforms}
                            />
                        </div>
                    </div>
                    <div className="main__form-one-inputs-block">
                        <div className="main__form-input-alone">
                            <ReportInput
                                name="cost"
                                title="Цена"
                                type="number"
                                value={values.cost}
                                onChange={handleChangeValues}
                                placeholder="Введите цену"
                                error={errors.cost}
                            />
                        </div>
                    </div>

                    <div className="main__form-one-inputs-block">
                        <div className="main__form-input-alone">
                            <ReportInput
                          multiple
                                name="files"
                                title="Файлы"
                                type="file"
                                value={values.files}
                                onChange={handleChangeFilesValues}
                                placeholder="Прикрепите файлы"
                                error={errors.files}
                              
                            />
                        </div>
                        
                    </div>
                
                    <ul style={{color: 'black'}}>{files.map(file =>
                        <li style={{
                        marginTop: '2px'
                    }}><button onClick={() => {handleRemoveFile(file.name)}} style={{color: '#8a4af3',
                        cursor: 'pointer',
                        marginRight: '2px'
                    }}>[X]</button>{file.name && file.name}</li>
)}</ul> 
                    {filesError && <span style={{
                            color: '#ea2f58'
                        }}>Максимальное количество файлов: 3</span>}
                 
                    <div className="main__form__buttons">
                        <button disabled={filesError} className="main__form__buttons-save" style={{
                            backgroundColor: filesError && '#ddd',
                            cursor: filesError && 'forbidden'
                        }} onClick={onSave}>
                            Создать
                        </button>
{!modal ?   <Link to="/app/main">
                            <button className="main__form__buttons-discard">
                                Выйти
                            </button>
                        </Link> :  
                            <button  onClick={() => {setReportWindowIsOpen(false); setModalIsOpen(false)}} className="main__form__buttons-discard">
                                Выйти
                            </button>
                       }
                     
                    </div>
                </div>
                    {isLoading && (
                        <Loader/>
                    )}
            </div>
        </>
    );
};

export default CreateReport;
