import React from "react";

function RightArrowIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="12"
            fill="none"
            viewBox="0 0 8 12"
        >
            <path
                fill="#7367F0"
                d="M4.68 5.997l-4.488 4.49A.65.65 0 000 10.95c0 .176.068.34.192.464l.393.393a.651.651 0 00.464.192c.176 0 .34-.068.464-.192l5.345-5.345a.65.65 0 00.192-.465.651.651 0 00-.192-.466L1.518.192A.651.651 0 001.054 0 .651.651 0 00.59.192L.197.585a.657.657 0 000 .928L4.68 5.997z"
            />
        </svg>
    );
}

export default RightArrowIcon;
