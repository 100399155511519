import MainTableApplication from '../../components/MainTableApplications/MainTableApplication.js'

const Applications = () => {

    return(
        <MainTableApplication type='applications' />
    )

}



export default Applications