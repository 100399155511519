import "./index.scss";
import Input from "../../components/Input";
import {Link, useNavigate} from "react-router-dom";
import CheckBox from "../../components/CheckBox";
import {setRememberData, setTokens, setUser} from "../../redux/auth";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import ButtonFilled from "../../components/ButtonFilled";
import TokenStorage from "../../api/TokenStorage";
import {post} from "../../api/request";
import {BallTriangle} from "react-loader-spinner";
import Loader, {useLoader} from "../../components/Loader";
import GlobalError from "../../components/GlobalError";
import {useAlert} from "../../components/AlertNotification";

const Login = () => {

    const alertU = useAlert();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const rememberData = useSelector((state) => state.auth.rememberData);

    const [isLoading, setIsLoading] = useState(false);
    const [login, setLogin] = useState(rememberData?.login);
    const [password, setPassword] = useState(rememberData?.password);
    const [isViewPassword, setIsViewPassword] = useState(false);
    const [remember, setRemember] = useState(false);
    const [errors, setErrors] = useState({
        login: '',
        password: ''
    });

    const validation = () => {
        let valid = true;
        if (!login) {
            setErrors((prev) => ({
                ...prev,
                login: 'Неверный логин'
            }))
            valid = false;
        }
        if (!password) {
            setErrors((prev) => ({
                ...prev,
                password: 'Неверный пароль'
            }))
            valid = false;
        }
        return valid
    };

    const handleChangeLogin = (evt) => {
        setLogin(evt.target.value);
        setErrors((prev) => ({
            ...prev,
            login: ''
        }))
    };

    const handleChangePassword = (evt) => {
        setPassword(evt.target.value);
        setErrors((prev) => ({
            ...prev,
            password: ''
        }))
    };

    const submit = async () => {
        if (!validation()) return;
        setIsLoading(true);

        if (remember) {
            dispatch(setRememberData({login, password}));
        } else {
            dispatch(setRememberData({login: '', password: ''}));
        }

        const requestData = {
            username: login,
            password
        };

        post('login', requestData)
            .then(async (response) => {
                if (response.status === 'success'){
                    await dispatch(setTokens({
                        accessToken: response.data.tokens.accessToken,
                        refreshToken: response.data.tokens.refreshToken
                    }));
                    await dispatch(setUser(response.data.user));
                    await TokenStorage.setAccessToken(response.data.tokens.accessToken)
                    await TokenStorage.setRefreshToken(response.data.tokens.refreshToken)
                    await TokenStorage.setUser(response.data.user)

                    await navigate('/app/main');
                } else {
                    alertU({
                        status: 'error',
                        title: 'Создание актеты',
                        message: 'Вы успешно создали новую заявку'
                    });
                }
            })
            .catch((e) => {
            
                if (e?.response?.data?.status === 'error') {
                    if (e?.response?.data?.message === 'Password or username are wrong') {
                        alertU({
                            status: 'error',
                            title: 'Авторизация',
                            message: 'Неверный логин или пароль'
                        });
                    } else if (e?.response?.data?.message === 'Password length must be at least 8 characters long') {
                        alertU({
                            status: 'error',
                            title: 'Авторизация',
                            message: 'Минимальная длина пароля: 8 символов'
                        });
                    }
                } else {
                    if (e?.message === 'Network Error') {
                        alertU({
                            status: 'error',
                            title: 'Авторизация',
                            message: 'Ошибка соединения, повторите попытку позже'
                        });
                    } else {
                        alertU({
                            status: 'error',
                            title: 'Авторизация',
                            message: 'Неизвестная ошибка, повторите попытку позже'
                        });
                    }
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }


    return (

        <div className="login-page">
            <div className="login-form__wrapper">
                <div className="login__right">
                    <div className="sign-in__wrap-input">
                        <Input
                            value={login}
                            onChange={handleChangeLogin}
                            leftIcon="profile"
                            placeholder="Логин"
                            error={errors.login}/>
                    </div>
                    <div className="sign-in__wrap-input">
                        <Input
                            value={password}
                            onChange={handleChangePassword}
                            leftIcon="lock"
                            rightIcon={isViewPassword ? "eye" : "eye-lock"}
                            onClickRight={() => setIsViewPassword(prev => !prev)}
                            type={isViewPassword ? "text" : "password"}
                            placeholder="Пароль"
                            error={errors.password}/>
                    </div>
                    <div className="sign-in__wrap-checkbox">
                        <CheckBox
                            text="Запомнить"
                            value={remember}
                            onChange={setRemember}/>
                    </div>
                    <div className="sign-in__wrap-btn">
                        <ButtonFilled
                            text="Войти"
                            onClick={submit}/>
                    </div>
                    <div className="sign-in__wrap-recovery">
                        <Link text="Забыли пароль?" to="/auth/password-recovery" translate="no"/>
                    </div>

                </div>

                <div className="login__left">
                    <img src="/img/login-img.png" alt="img"/>
                </div>
            </div>

            {isLoading && (
                <Loader/>
            )}
        </div>

    );
};

export default Login;
