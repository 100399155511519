import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {logout} from '../../redux/auth';
import {useNavigate} from 'react-router-dom';
import "./index.scss";
import {Outlet} from 'react-router-dom';
import {HashLink as Link} from 'react-router-hash-link';
import ProfileSection from "../../sections/ProfileSection";
import Container from "../../components/Container";
import TimerSection from "../../sections/TimerSection";
import MobProfileSection from "../../sections/MobProfileSection";
import {useGet} from "../../api/request";
import TokenStorage from "../../api/TokenStorage";
import Loader from "../../components/Loader";
import GlobalError from "../../components/GlobalError";
import Confirm from "../../components/Confirm";
import {useAlert} from "../../components/AlertNotification";

const MainLayout = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const getU = useGet();
    const alertU = useAlert();

    const user = useSelector(state => state.auth.user)

    const onLogout = () => {
        dispatch(logout())
        TokenStorage.logOut();
        navigate("/login")
    }

    const [isLoading, setIsLoading] = useState(false);
    const [userInfo, setUserInfo] = useState({
        avatar: '/img/defaultAvatar.png',
        name: '',
        surname: '',
        status: true,
        role: '',
        position: false,
        email: '',
        contact: '',
        username: 'User'
    });


    const getUserData = () =>{
        setIsLoading(true)
        getU('users/current')
            .then((response) =>{
                if (response.status === "success"){
                    const userData = response.data.user;

                    setUserInfo({
                        avatar: userData.avatar
                            ? `${process.env.REACT_APP_API_URL}/uploads/avatars/${userData.avatar}`
                            : '/img/defaultAvatar.png',
                        name: userData.name || '---',
                        surname: userData.surname || '---',
                        status: true,
                        role: userData.role || '---',
                        position: userData.isSenior ? "Старший менеджер": "Младший менеджер" || false,
                        email: userData.email || '---',
                        contact: userData.contact || '---',
                        username: userData.username || 'User'
                    })
                }
            })
            .catch((e) =>{
                alertU({
                    status: 'error',
                    title: 'Получение данных пользователя',
                    message: e.response.data.message
                });
            })
            .finally(() =>{
                setIsLoading(false)
            })
    }

    const isReadyToWork = useSelector(state => state.work.isReadyToWork)

    const [modalStatus, setModalStatus] = useState({
        isActive: false,
    });

    const onOpenProfile = () => {
        if (isReadyToWork) {
            setModalStatus({isActive: true})

        } else {
            navigate('/app/profile')
        }
    }

    useEffect(() =>{
        getUserData();
    },[])


    return (
        <Container>
            <div className="main-wrapper">
                <div className="main-profile">
                    <ProfileSection
                        userInfo={userInfo}
                        onLogout={onLogout}
                        onOpenProfile={onOpenProfile}
                    />
                </div>
                <div className="main-mob-profile">
                    <MobProfileSection
                        userInfo={userInfo}
                        onLogout={onLogout}
                        onOpenProfile={onOpenProfile}
                    />
                </div>
                <div className="main-info">

                    <div className="main-info__timer">
                        <TimerSection/>
                    </div>
                    <div className="main-info__outlet">
                        <Outlet/>
                    </div>
                </div>
                {modalStatus.isActive === true &&
                <Confirm modalStatus={modalStatus} setModalStatus={setModalStatus}/>
                }
                {isLoading && (
                    <Loader/>
                )}
            </div>
        </Container>
    );
};

export default MainLayout;
