import React, { createContext, useState } from 'react';

export const ActionContext = createContext(false);

export const ActionProvider = ({ children }) => {
    const [action, setAction] = useState(false);

    return (
        <ActionContext.Provider value={{ action, setAction }}>
            {children}
        </ActionContext.Provider>
    );
}
