import { useState } from 'react';
import { useDispatch } from 'react-redux';
import './styles.scss';

const Input = ({
				   value,
				   onChange,
				   leftIcon,
				   rightIcon,
				   error,
				   onClickRight,
				   ...props
			   }) => {
	const [isFocus, setIsFocus] = useState(false);
	const dispatch = useDispatch();
	return (
		<div className="input">
			<div className={`input__wrap-login${isFocus ? ' input__wrap_focus' : ''}${error ? ' input__wrap_error' : ''}`}>
				{leftIcon && (
					<div className="input__left">
						{leftIcon === "profile" && (
							<img src="/img/icon_profile.svg" alt="icon profile" />
						)}
						{leftIcon === "lock" && (
							<img src="/img/icon_lock.svg" alt="icon lock" />
						)}
						{leftIcon === "mail" && (
							<img src="/img/icon_mail.svg" alt="icon mail" />
						)}
					</div>
				)}
				<input
					className="input__input"
					type="text"
					value={value}
					onChange={onChange}
					autoComplete="off"
					onFocus={() => {
						setIsFocus(true);
					}}
					onBlur={() => {
						setIsFocus(false);
					}}
					{...props} />
				{rightIcon && (
					<div className={`input__right${onClickRight ? ' input__right_clicked' : ''}`} onClick={onClickRight}>
						{rightIcon === "pen" && (
							<img src="/img/icon_pen.svg" alt="icon pen" />
						)}
						{rightIcon === "eye-lock" && (
							<img src="/img/icon_eye-lock.svg" alt="icon eye lock" />
						)}
						{rightIcon === "eye" && (
							<img src="/img/icon_eye.svg" alt="icon eye lock" />
						)}
					</div>
				)}
			</div>
			{(error && typeof error == 'string') && (
				<div className="input__error">
					<p className="input__error-text">{error}</p>
				</div>
			)}
		</div>
	);
};

export default Input;
