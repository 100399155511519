import "./index.scss";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/auth";
import TokenStorage from "../../api/TokenStorage";
import { Link, useNavigate } from "react-router-dom";
import UserInfoItem from "../../components/UserInfoItem";
import ReportInput from "../../components/ReportInput";
import Selector from "../../components/Selector";
import { useEffect, useState } from "react";
import LeftArrowIcon from "../../assets/icons/LeftArrowIcon";
import { useDelete, useGet, usePost, usePut } from "../../api/request";
import GlobalError from "../../components/GlobalError";
import Loader from "../../components/Loader";
import { useAlert } from "../../components/AlertNotification";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from "chart.js";

const Profile = () => {
  const navigate = useNavigate();
  const getU = useGet();
  const postU = usePost();
  const putU = usePut();
  const deleteU = useDelete();
  const alertU = useAlert();

  const [isLoading, setIsLoading] = useState(true);

  const [avatar, setAvatar] = useState("/img/defaultAvatar.png");

  const [values, setValues] = useState({
    name: "",
    surname: "",
    contact: "",
    avatar: "",
    password: "",
    confirm: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    surname: "",
    contact: "",
    avatar: "",
    password: "",
    confirm: "",
  });

  const [isDataChanged, setIsDataChanged] = useState({
    name: false,
    surname: false,
    contact: false,
    avatar: false,
    password: false,
    confirm: false,
  });

  const [data, setData] = useState({
    hours: 0,
    pay: 0,
    applicationsCount: 0,
    notActiveApplicationsCount: 0,
    nonTargetApplicationsCount: 0,
    rejectedApplicationsCount: 0,
    contactApplicationsCount: 0,
    successApplicationsCount: 0,
    paidApplicationsCount: 0,
  });

  const handleChangeValues = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    setErrors({
      ...errors,
      [event.target.name]: "",
    });
    setIsDataChanged({
      ...isDataChanged,
      [event.target.name]: true,
    });
  };

  const handleAvaChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setValues({ ...values, avatar: img });
      setIsDataChanged({
        ...isDataChanged,
        avatar: true,
      });
      setAvatar(URL.createObjectURL(img));
    }
  };

  const infoValidation = () => {
    let isValid = true;
    let formErrors = { ...errors };

    if (values.name === "") {
      formErrors.name = "Поле должно быть заполненно";
      isValid = false;
    }

    if (values.surname === "") {
      formErrors.surname = "Поле должно быть заполненно";
      isValid = false;
    }

    if (values.contact === "") {
      formErrors.contact = "Поле должно быть заполненно";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const passwordValidation = () => {
    let isValid = true;
    let formErrors = { ...errors };

    if (values.password === "") {
      formErrors.password = "Поле должно быть заполненно";
      isValid = false;
    } else if (values.password.length < 7) {
      formErrors.password = "Минимальная длина пароля 8 симоволов";
      isValid = false;
    } else if (values.password !== values.confirm) {
      formErrors.password = "Пароли не совпадают";
      isValid = false;
    }

    if (values.confirm === "") {
      formErrors.confirm = "Поле должно быть =заполненно";
      isValid = false;
    } else if (values.confirm.length < 7) {
      formErrors.confirm = "Минимальная длина пароля 8 симоволов";
      isValid = false;
    } else if (values.password !== values.confirm) {
      formErrors.confirm = "Пароли не совпадают";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const onSaveAvatar = () => {
    setIsLoading(true);

    let data = new FormData();
    if (isDataChanged.avatar) {
      data.append("avatar", values.avatar);
      putU("users/current", data)
        .then((response) => {
          if (response.status === "success") {
            alertU({
              status: "success",
              title: "Обновление аватара",
              message: "Вы успешно обновили аватар",
            });
          }
        })
        .catch(() => {
          alertU({
            status: "error",
            title: "Обновление аватара",
            message: "Произошла ошибка при обновлении аватара",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      alertU({
        status: "error",
        title: "Обновление аватара",
        message: "Ошибка сервера",
      });
    }
  };

  const onSaveInfo = () => {
    if (!infoValidation()) return;

    setIsLoading(true);

    let data = {};

    if (isDataChanged.name) {
      data.name = values.name;
    }
    if (isDataChanged.surname) {
      data.surname = values.surname;
    }
    if (isDataChanged.contact) {
      data.contact = values.contact;
    }

    if (Object.keys(data).length) {
      putU("users/current", data)
        .then((response) => {
          if (response.status === "success") {
            alertU({
              status: "success",
              title: "Обновление профиля",
              message: "Вы успешно обновили данные пользователя",
            });
          }
        })
        .catch(() => {
          alertU({
            status: "error",
            title: "Обновление профиля",
            message: "Произошла ошибка при обновлении пользователя",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      alertU({
        status: "error",
        title: "Обновление профиля",
        message: "Произошла ошибка при обновлении пользователя",
      });
    }
  };

  const onSavePassword = () => {
    if (!passwordValidation()) return;

    setIsLoading(true);
    const data = {};

    if (isDataChanged.password) {
      data.password = values.password;
    }

    putU("users/current/password", data)
      .then((response) => {
        if (response.status === "success") {
          alertU({
            status: "success",
            title: "Обновление пароля",
            message: "Вы успешно обновили пароль",
          });
          setValues({
            ...values,
            password: "",
            confirm: "",
          });
        }
      })
      .catch((e) => {
        alertU({
          status: "error",
          title: "Обновление пароля",
          message: e.response.data.message,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getU("users/current")
      .then((response) => {
        if (response.status === "success") {
          setAvatar(
            response.data.user?.avatar
              ? `${process.env.REACT_APP_API_URL}/uploads/avatars/${response.data.user?.avatar}`
              : "/img/defaultAvatar.png"
          );
          setValues({
            ...values,
            name: response.data.user?.name || "",
            surname: response.data.user?.surname || "",
            contact: response.data.user?.contact || "",
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
      getU("users/current/statistics/")
      .then((response) => {
        if (response.status === "success") {
          const statisticList = response.data.statistics;

          setData({
            hours: statisticList.hours || 0,
            pay: statisticList.pay || 0,
            applicationsCount: statisticList.applicationsCount || 0,
            notActiveApplicationsCount:
              statisticList.notActiveApplicationsCount || 0,
            nonTargetApplicationsCount:
              statisticList.nonTargetApplicationsCount || 0,
            rejectedApplicationsCount:
              statisticList.rejectedApplicationsCount || 0,
            contactApplicationsCount:
              statisticList.contactApplicationsCount || 0,
            successApplicationsCount:
              statisticList.successApplicationsCount || 0,
            paidApplicationsCount: statisticList.paidApplicationsCount || 0,
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );


  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const labels = ["Заявки", "Лиды", "Клиенты"];

  const barData = {
    labels,
    datasets: [
      {
        label: "Статистика за месяц",
        data: [
          data.applicationsCount,
          data.contactApplicationsCount,
          data.paidApplicationsCount,
        ],
        backgroundColor: "rgb(115,103,240)",
      },
    ],
  };

  return (
    <div>
      <div className="profile">
        <div className="profile__title">Профиль</div>
        <div>
          <div className="profile__avatar">
            <input type="file" onChange={handleAvaChange} />
            <img src={avatar} alt="" />
          </div>
          <div className="profile__buttons">
            <button className="main__form__buttons-save" onClick={onSaveAvatar}>
              Сохранить аватар
            </button>
          </div>
        </div>
        <div>
          <div className="main__form-two-inputs-block">
            <div className="main__form-input">
              <ReportInput
                name="name"
                title="Имя"
                value={values.name}
                onChange={handleChangeValues}
                placeholder="Введите ваше имя"
                error={errors.name}
              />
            </div>
            <div className="main__form-input">
              <ReportInput
                name="surname"
                title="Фамилия"
                value={values.surname}
                onChange={handleChangeValues}
                placeholder="Введите вашу фамилию"
                error={errors.surname}
              />
            </div>
          </div>
          <div className="main__form-one-inputs-block">
            <div className="main__form-input-alone">
              <ReportInput
                name="contact"
                title="Контакт"
                value={values.contact}
                onChange={handleChangeValues}
                placeholder="Введите любой ваш контакт"
                error={errors.contact}
              />
            </div>
          </div>
          <div className="profile__buttons">
            <button className="main__form__buttons-save" onClick={onSaveInfo}>
              Сохранить информацию
            </button>
          </div>
        </div>
        <div>
          <div className="main__form-two-inputs-block">
            <div className="main__form-input">
              <ReportInput
                name="password"
                title="Пароль"
                type="password"
                value={values.password}
                onChange={handleChangeValues}
                placeholder="Введите пароль"
                error={errors.password}
              />
            </div>
            <div className="main__form-input">
              <ReportInput
                name="confirm"
                type="password"
                title="Повторите пароль"
                value={values.confirm}
                onChange={handleChangeValues}
                placeholder="Введите повторно пароль"
                error={errors.confirm}
              />
            </div>
          </div>
          <div className="profile__buttons">
            <button
              className="main__form__buttons-save"
              onClick={onSavePassword}
            >
              Сохранить пароль
            </button>
            <Link to="/app/main">
              <button className="main__form__buttons-discard">Выйти</button>
            </Link>
          </div>
        </div>

        <div className="bar">
          <div className="profile__title">Заявки/Лиды/Клиенты</div>
          <Bar options={options} data={barData} updateMode={"none"} />
        </div>
      </div>

      {isLoading && <Loader />}
    </div>
  );
};

export default Profile;
